import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Context as AuthContext } from '../context/AuthContext'

// Context.
import api from '../api/api'

// Libraries.
import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

export default function MainPage () {
  // State.
  const { state: { canUploadResults, user } } = useContext(AuthContext)
  const { logout } = useContext(AuthContext)
  const [receptionistName, setReceptionistName] = useState('')
  const [countries, setCountries] = useState([])
  const [iso2, setIso2] = useState(process.env.NODE_ENV === 'development' ? 'PL' : 'AE')
  const [phone, setPhone] = useState(process.env.NODE_ENV === 'development' ? '222222222' : '')
  const [personalId, setPersonalId] = useState('')
  const [patient, setPatient] = useState({
    userId: undefined,
    firstName: '',
    lastName: '',
    birthdate: '',
    personalId: '',
    lisId: '',
    verified: false
  })
  const [noResults, setNoResults] = useState(false)
  const [patientSaved, setPatientSaved] = useState(false)
  const [patientConfirmed, setPatientConfirmed] = useState(false)

  const [transaction, setTransaction] = useState({
    priceAed: '',
    paidHeartBits: '',
    billNumber: '',
    comment: '',
    paidAed: ''
  })
  const [transactionConfirmed, setTransactionConfirmed] = useState(false)
  const [accountBalance, setAccountBalance] = useState('')
  const [transactionSaved, setTransactionSaved] = useState(false)

  const [reward, setReward] = useState({
    paidHeartBits: '',
    rewardName: ''
  })
  const [rewardConfirmed, setRewardConfirmed] = useState(false)
  const [rewardRedeemed, setRewardRedeemed] = useState(false)

  const [results, setResults] = useState({
    file: undefined,
    testBatchDate: ''
  })
  const [resultsUploaded, setResultsUploaded] = useState(false)
  const [resultsError, setResultsError] = useState(false)

  // Styles.
  const classes = useStyles()

  // Effects.
  useEffect(() => {
    // API call.
    const fetchCountries = async () => {
      console.log('Fetching countries')
      const response = await api.post('/security/get-countries')
      if (response.data.success) {
        setCountries(response.data.countries)
      } else {
        // TODO: Implement.
      }
    }
    fetchCountries()
  }, [])

  useEffect(() => {
    if (user !== null) {
      let name = ''
      try {
        const receptionist = JSON.parse(user)
        name = `${receptionist.FirstName ? receptionist.FirstName : ''} ${receptionist.LastName ? receptionist.LastName : ''}`
      } catch (e) {
        name = user
      }
      setReceptionistName(name)
    }
  }, [user])

  // Validation
  const QuerySchema = Yup.object().shape({
    iso2: Yup
      .string(),
    phone: Yup
      .string()
      .matches(/^[\d\s]+$/, 'Invalid phone number!'),
    personalId: Yup
      .string()
      .matches(/\d{3}-\d{4}-\d{7}-\d$/, 'Invalid Emirates ID!')
  })

  const SaveSchema = Yup.object().shape({
    personalId: Yup.string()
      .matches(/\d{3}-\d{4}-\d{7}-\d$/, 'Invalid Emirates ID!')
  })

  const TransactionSchema = Yup.object().shape({
    priceAed: Yup
      .string()
      .label('Price')
      .matches(/^\d+(.\d{1,2})?$/, 'Invalid price!')
      .required(),
    paidHeartBits: Yup
      .number()
      .integer()
      .label('HeartBits')
      .max(accountBalance, 'Not enough HeartBits available!')
      .min(0),
    billNumber: Yup
      .string()
      .label('Bill number')
      .required()
  })

  const RewardSchema = Yup.object().shape({
    paidHeartBits: Yup
      .number()
      .integer()
      .label('HeartBits')
      .max(accountBalance, 'Not enough HeartBits available!')
      .positive()
      .required(),
    rewardName: Yup
      .string()
      .label('Reward name')
      .required()
  })

  const applyDiscount = (price, paidHeartBits) => {
    return price - paidHeartBits * 0.01
  }

  const handleQueryButtonClick = async (values) => {
    resetPatientForm()
    resetTransactionForm()
    resetUploadForm()

    var response
    if (values.iso2 !== '' && values.phone !== '') {
      // ignore leading zeros and white spaces
      response = await api.post('/receptionist/get-details', { iso2: values.iso2, phone: values.phone.replace(/^[0|\s]*|\s/g, '') })
    } else if (values.personalId !== '') {
      response = await api.post('/receptionist/get-details', { personalId: values.personalId })
    } else {
      return
    }
    if (response.data.success) {
      setPatient({
        userId: response.data.details.UserId,
        firstName: response.data.details.FirstName !== undefined ? response.data.details.FirstName : '',
        lastName: response.data.details.LastName !== undefined ? response.data.details.LastName : '',
        birthdate: response.data.details.Birthdate !== undefined ? response.data.details.Birthdate.substr(0, 10) : '',
        personalId: response.data.details.PersonalId !== undefined ? response.data.details.PersonalId : '',
        lisId: response.data.details.LisId !== undefined ? response.data.details.LisId : '',
        verified: !!(response.data.details.Verified !== undefined && response.data.details.Verified === 1)
      })
      setAccountBalance(response.data.details.AccountBalance !== undefined ? response.data.details.AccountBalance : '')
      setNoResults(false)
    } else {
      setNoResults(true)
    }
  }

  const resetQueryForm = () => {
    setPhone('')
    setIso2(process.env.NODE_ENV === 'development' ? 'PL' : 'AE')
    setPersonalId('')
    setNoResults(false)

    resetPatientForm()
    resetTransactionForm()
    resetUploadForm()
    patient.userId = undefined
  }

  const handleSavePatientButtonClick = async (patientData) => {
    const params = {
      userId: patientData.userId
    }

    if (patientData.firstName !== '') {
      params.firstName = patientData.firstName
    }
    if (patientData.lastName !== '') {
      params.lastName = patientData.lastName
    }
    if (patientData.birthdate !== '') {
      params.birthdate = patientData.birthdate + 'T00:00:00Z'
    }
    if (patientData.personalId !== '') {
      params.personalId = patientData.personalId
    }
    if (patientData.lisId !== '') {
      params.lisId = patientData.lisId
    }
    const response = await api.post('/receptionist/set-details', params)
    if (response.data.success) {
      setPatientSaved(true)
      setPatient({ ...patient, ...{ verified: true } })
    } else {
      setPatientSaved(false)
    }
  }

  const resetPatientForm = () => {
    setPatient({
      userId: patient.userId,
      firstName: '',
      lastName: '',
      birthdate: '',
      personalId: '',
      lisId: '',
      verified: patient.verified
    })
    setPatientSaved(false)
    setPatientConfirmed(false)
  }

  const handleSaveTransactionButtonClick = async (transaction) => {
    const params = {
      userId: patient.userId,
      paidAed: applyDiscount(transaction.priceAed, transaction.paidHeartBits),
      paidHeartBits: parseInt(transaction.paidHeartBits),
      billNumber: transaction.billNumber
    }

    if (transaction.comment !== '') {
      params.comment = transaction.comment
    }

    const response = await api.post('/receptionist/add-transaction', params)
    if (response.data.success) {
      setAccountBalance(response.data.accountBalance !== undefined ? response.data.accountBalance : '')
      setTransactionSaved(true)
    } else {
      setTransactionSaved(false)
    }
  }

  const resetTransactionForm = () => {
    setTransaction({
      priceAed: '',
      paidHeartBits: '',
      billNumber: '',
      comment: '',
      paidAed: ''
    })
    setTransactionSaved(false)
    setTransactionConfirmed(false)
  }

  const handleRedeemRewardButtonClick = async (reward) => {
    const params = {
      userId: patient.userId,
      paidHeartBits: parseInt(reward.paidHeartBits),
      comment: reward.rewardName
    }

    const response = await api.post('/receptionist/add-transaction', params)
    if (response.data.success) {
      setAccountBalance(response.data.accountBalance !== undefined ? response.data.accountBalance : '')
      setRewardRedeemed(true)
    } else {
      setRewardRedeemed(false)
    }
  }

  const resetRewardForm = () => {
    setReward({
      paidHeartBits: '',
      rewardName: ''
    })
    setRewardRedeemed(false)
    setRewardConfirmed(false)
  }

  const handleUpload = async (values) => {
    const params = new FormData()
    params.append('date', values.testBatchDate + 'T00:00:00Z')
    params.append('results', values.file)

    const response = await api.post('/receptionist/notify-results', params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    if (response.data.success) {
      setResultsUploaded(true)
      setResultsError(false)
    } else if (response.data) {
      setResultsUploaded(false)
      setResultsError(true)
    }
  }

  const resetUploadForm = () => {
    setResults({
      file: '',
      testBatchDate: ''
    })
    setResultsUploaded(false)
    setResultsError(false)
  }

  return (countries && countries.length > 0 && user &&
    (
      <Container component='main' maxWidth='sm' className={classes.container}>
        <CssBaseline />
        <Box className={classes.logo}>
          <img
            alt='MenaLabs logo'
            className={classes.logoImg}
            src={process.env.PUBLIC_URL + '/logo.png'}
          />
          <Typography className={classes.buttons}>
            <Button
              color='primary'
              onClick={logout}
            >Logout
            </Button>
          </Typography>
        </Box>

        {/* PATIENT QUERY */}
        <Formik
          className={classes.form}
          enableReinitialize
          initialValues={{ iso2: iso2, phone: phone, personalId: personalId }}
          onSubmit={(values) => handleQueryButtonClick(values)}
          validateOnBlur
          validateOnChange
          validationSchema={QuerySchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form>
              <Paper square variant='outlined' className={classes.query}>
                <Grid container spacing={0} className={classes.form}>
                  <Grid item xs={12}>
                    <Typography variant='h6' className={classes.header}>Patient query</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Phone:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputLabel id='country-label' shrink>Country</InputLabel>
                    <Field
                      children={countries.map(i => <MenuItem key={i.Iso2} value={i.Iso2}> {i.Name} </MenuItem>)}
                      component={Select}
                      disabled={values.personalId !== ''}
                      error={errors.iso2 && touched.iso2}
                      fullWidth
                      id='iso2'
                      inputProps={{
                        name: 'iso2',
                        id: 'iso2-native-helper'
                      }}
                      labelId='country-label'
                      margin='dense'
                      name='iso2'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      size='small'
                      value={values.iso2}
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={8}>
                    <Field
                      autoComplete='tel-national'
                      autoFocus
                      component={TextField}
                      disabled={values.personalId !== ''}
                      error={errors.phone && touched.phone}
                      fullWidth
                      helperText={errors.phone && touched.phone ? errors.phone : ''}
                      id='phone'
                      label='Phone'
                      margin='dense'
                      name='phone'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      size='small'
                      type='tel'
                      value={values.phone}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Emirates ID:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={TextField}
                      disabled={values.phone !== ''}
                      error={errors.personalId && touched.personalId}
                      fullWidth
                      helperText={errors.personalId && touched.personalId ? errors.personalId : ''}
                      id='personalId'
                      label='Emirates ID'
                      margin='dense'
                      name='personalId'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      size='small'
                      type='text'
                      value={values.personalId}
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
              </Paper>
              {noResults &&
                <Typography
                  align='center'
                  className={classes.message}
                  color='error'
                  variant='body1'
                >
                  No patient found!
                </Typography>}
              <div className={classes.buttons}>
                <Button
                  className={classes.button}
                  color='primary'
                  onClick={() => resetQueryForm()}
                  type='reset'
                  variant='contained'
                >
                  Clear
                </Button>
                <Button
                  className={classes.button}
                  color='primary'
                  disabled={isSubmitting}
                  type='submit'
                  variant='contained'
                >
                  Query
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        {/* PATIENT */}
        <Formik
          className={classes.form}
          enableReinitialize
          initialValues={patient}
          onSubmit={(values) => handleSavePatientButtonClick(values)}
          validateOnBlur
          validateOnChange
          validationSchema={SaveSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form>
              <Paper square variant='outlined' className={classes.patient}>
                <Grid container spacing={0} className={classes.form}>
                  <Grid item xs={12}>
                    <Typography variant='h6' className={classes.header}>Patient</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>First Name:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      autoComplete='given-name'
                      component={TextField}
                      disabled={patient.userId === undefined}
                      error={errors.firstName && touched.firstName}
                      fullWidth
                      helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
                      id='firstName'
                      label='First Name'
                      margin='dense'
                      name='firstName'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      size='small'
                      type='text'
                      value={values.firstName}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Last Name:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      autoComplete='family-name'
                      component={TextField}
                      disabled={patient.userId === undefined}
                      error={errors.lastName && touched.lastName}
                      fullWidth
                      helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
                      id='lastName'
                      label='Last Name'
                      margin='dense'
                      name='lastName'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      size='small'
                      type='text'
                      value={values.lastName}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Birth Date:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      autoComplete='bday'
                      component={TextField}
                      disabled={patient.userId === undefined}
                      error={errors.birthdate && touched.birthdate}
                      fullWidth
                      helperText={errors.birthdate && touched.birthdate ? errors.birthdate : ''}
                      id='birthdate'
                      margin='dense'
                      name='birthdate'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      size='small'
                      type='date'
                      value={values.birthdate}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Emirates ID:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={TextField}
                      disabled={patient.userId === undefined}
                      error={errors.personalId && touched.personalId}
                      fullWidth
                      helperText={errors.personalId && touched.personalId ? errors.personalId : ''}
                      id='personalId'
                      label='Emirates ID'
                      margin='dense'
                      name='personalId'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      size='small'
                      type='text'
                      value={values.personalId}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>LDM ID:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={TextField}
                      disabled
                      error={errors.lisId && touched.lisId}
                      fullWidth
                      helperText={errors.lisId && touched.lisId ? errors.lisId : ''}
                      id='lisId'
                      label='LDM ID'
                      margin='dense'
                      name='lisId'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      size='small'
                      type='text'
                      value={values.lisId}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {patient.userId !== undefined && (
                      patient.verified ? (
                        <Typography
                          align='center'
                          className={classes.message}
                          color='primary'
                          variant='body1'
                        >
                          Patient data verified.
                        </Typography>)
                        : (
                          <Typography
                            align='center'
                            className={classes.message}
                            color='error'
                            variant='body1'
                          >
                            Patient data not verified.
                          </Typography>)
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox color='primary' />}
                      disabled={patient.userId === undefined}
                      id='patientConfirmed'
                      label={`I, ${receptionistName}, confirm the patient data were verified and are correct.`}
                      labelPlacement='end'
                      name='patientConfirmed'
                      onChange={() => setPatientConfirmed(!patientConfirmed)}
                      required
                      checked={patientConfirmed === true}
                    />
                  </Grid>
                </Grid>
              </Paper>
              {patientSaved &&
                <Typography
                  align='center'
                  className={classes.message}
                  color='primary'
                  variant='body1'
                >
                  Patient information updated.
                </Typography>}
              <div className={classes.buttons}>
                <Button
                  className={classes.button}
                  color='primary'
                  disabled={patient.userId === undefined}
                  onClick={() => resetPatientForm()}
                  type='reset'
                  variant='contained'
                >
                  Clear
                </Button>
                <Button
                  className={classes.button}
                  color='primary'
                  disabled={patient.userId === undefined || !patientConfirmed || isSubmitting}
                  type='submit'
                  variant='contained'
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        {/* PAYMENT FOR SERVICE */}
        <Formik
          className={classes.form}
          enableReinitialize
          initialValues={transaction}
          onSubmit={(values, { resetForm }) => {
            handleSaveTransactionButtonClick(values)
            setTransactionConfirmed(false)
            resetForm()
          }}
          validateOnBlur
          validateOnChange
          validationSchema={TransactionSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form>
              <Paper square variant='outlined' className={classes.patient}>
                <Grid container spacing={0} className={classes.form}>
                  <Grid item xs={12}>
                    <Typography variant='h6' className={classes.header}>Payment for service</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>HeartBits Balance:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={TextField}
                      disabled
                      fullWidth
                      id='accountBalance'
                      label='HeartBits Balance'
                      margin='dense'
                      name='accountBalance'
                      onBlur={handleBlur}
                      size='small'
                      type='text'
                      value={accountBalance}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Original Price AED:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={TextField}
                      disabled={patient.userId === undefined}
                      error={errors.priceAed && touched.priceAed}
                      fullWidth
                      helperText={errors.priceAed && touched.priceAed ? errors.priceAed : ''}
                      id='priceAed'
                      inputProps={{ min: '0', step: '0.01' }}
                      label='Original Price AED'
                      margin='dense'
                      name='priceAed'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      required
                      size='small'
                      type='number'
                      value={values.priceAed}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Redeem HeartBits:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={TextField}
                      disabled={patient.userId === undefined}
                      error={errors.paidHeartBits && touched.paidHeartBits}
                      fullWidth
                      helperText={errors.paidHeartBits && touched.paidHeartBits ? errors.paidHeartBits : ''}
                      id='paidHeartBits'
                      inputProps={{ min: '0', max: accountBalance, step: '1' }}
                      label='Redeem HeartBits'
                      margin='dense'
                      name='paidHeartBits'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      size='small'
                      type='number'
                      value={values.paidHeartBits}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      align='center'
                      className={classes.message}
                      color={applyDiscount(values.priceAed, values.paidHeartBits).toFixed(2) >= 0 ? 'primary' : 'error'}
                      variant='body1'
                    >
                      {`Total price of ${isNaN(parseFloat(values.priceAed)) ? 0 : parseFloat(values.priceAed).toFixed(2)} AED will be paid partly in ${values.paidHeartBits > 0 ? values.paidHeartBits : 0} HB and the rest in ${applyDiscount(values.priceAed, values.paidHeartBits).toFixed(2)} AED. This purchase earns the patient new ${(applyDiscount(values.priceAed, values.paidHeartBits) * 10).toFixed(0)} HB.`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Bill Number:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={TextField}
                      disabled={patient.userId === undefined}
                      error={errors.billNumber && touched.billNumber}
                      fullWidth
                      helperText={errors.billNumber && touched.billNumber ? errors.billNumber : ''}
                      id='billNumber'
                      label='Bill Number'
                      margin='dense'
                      name='billNumber'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      required
                      size='small'
                      type='text'
                      value={values.billNumber}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Comment:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={TextField}
                      disabled={patient.userId === undefined}
                      error={errors.comment && touched.comment}
                      fullWidth
                      helperText={errors.comment && touched.comment ? errors.comment : ''}
                      id='comment'
                      label='Comment'
                      margin='dense'
                      multiline
                      name='comment'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      size='small'
                      type='text'
                      value={values.comment}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox color='primary' />}
                      disabled={patient.userId === undefined}
                      id='heartBitsConfirmed'
                      label={`I, ${receptionistName}, confirm the amount was paid.`}
                      labelPlacement='end'
                      name='heartBitsConfirmed'
                      onChange={() => { setTransactionConfirmed(!transactionConfirmed) }}
                      required
                      checked={transactionConfirmed === true}
                    />
                  </Grid>
                </Grid>
              </Paper>
              {transactionSaved &&
                <Typography
                  align='center'
                  className={classes.message}
                  color='primary'
                  variant='body1'
                >
                  Transaction saved.
                </Typography>}
              <div className={classes.buttons}>
                <Button
                  className={classes.button}
                  color='primary'
                  disabled={patient.userId === undefined}
                  onClick={() => resetTransactionForm()}
                  type='reset'
                  variant='contained'
                >
                  Clear
                </Button>
                <Button
                  className={classes.button}
                  color='primary'
                  disabled={
                    patient.userId === undefined ||
                    values.priceAed === '' ||
                    values.billNumber === '' ||
                    applyDiscount(values.priceAed, values.paidHeartBits).toFixed(2) < 0 ||
                    !transactionConfirmed || isSubmitting
                  }
                  type='submit'
                  variant='contained'
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        {/* REDEEM REWARD */}
        <Formik
          className={classes.form}
          enableReinitialize
          initialValues={reward}
          onSubmit={(values, { resetForm }) => {
            handleRedeemRewardButtonClick(values)
            setRewardConfirmed(false)
            resetForm()
          }}
          validateOnBlur
          validateOnChange
          validationSchema={RewardSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form>
              <Paper square variant='outlined' className={classes.patient}>
                <Grid container spacing={0} className={classes.form}>
                  <Grid item xs={12}>
                    <Typography variant='h6' className={classes.header}>Redeem Reward</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>HeartBits Balance:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={TextField}
                      disabled
                      fullWidth
                      id='accountBalance'
                      label='HeartBits Balance'
                      margin='dense'
                      name='accountBalance'
                      onBlur={handleBlur}
                      size='small'
                      type='text'
                      value={accountBalance}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Redeem HeartBits:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={TextField}
                      disabled={patient.userId === undefined}
                      error={errors.paidHeartBits && touched.paidHeartBits}
                      fullWidth
                      helperText={errors.paidHeartBits && touched.paidHeartBits ? errors.paidHeartBits : ''}
                      id='paidHeartBits'
                      inputProps={{ min: '0', max: accountBalance, step: '1' }}
                      label='Redeem HeartBits'
                      margin='dense'
                      name='paidHeartBits'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      required
                      size='small'
                      type='number'
                      value={values.paidHeartBits}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Reward Name:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      component={TextField}
                      disabled={patient.userId === undefined}
                      error={errors.rewardName && touched.rewardName}
                      fullWidth
                      helperText={errors.rewardName && touched.rewardName ? errors.rewardName : ''}
                      id='rewardName'
                      label='Reward Name'
                      margin='dense'
                      name='rewardName'
                      onBlur={handleBlur}
                      onChange={(value) => {
                        handleChange(value)
                      }}
                      required
                      size='small'
                      type='text'
                      value={values.rewardName}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox color='primary' />}
                      disabled={patient.userId === undefined}
                      id='rewardConfirmed'
                      label={`I, ${receptionistName}, confirm the data are correct.`}
                      labelPlacement='end'
                      name='rewardConfirmed'
                      onChange={() => { setRewardConfirmed(!rewardConfirmed) }}
                      required
                      checked={rewardConfirmed === true}
                    />
                  </Grid>
                </Grid>
              </Paper>
              {rewardRedeemed &&
                <Typography
                  align='center'
                  className={classes.message}
                  color='primary'
                  variant='body1'
                >
                  Reward redeemed.
                </Typography>}
              <div className={classes.buttons}>
                <Button
                  className={classes.button}
                  color='primary'
                  disabled={patient.userId === undefined}
                  onClick={() => resetRewardForm()}
                  type='reset'
                  variant='contained'
                >
                  Clear
                </Button>
                <Button
                  className={classes.button}
                  color='primary'
                  disabled={patient.userId === undefined ||
                    values.paidHeartBits === '' ||
                    parseInt(values.paidHeartBits) < 1 ||
                    values.rewardName === '' ||
                    !rewardConfirmed || isSubmitting}
                  type='submit'
                  variant='contained'
                >
                  Redeem
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        {/* UPLOAD RESULTS */}
        {canUploadResults &&
          <Formik
            className={classes.form}
            enableReinitialize
            initialValues={results}
            onSubmit={(values, { resetForm }) => {
              handleUpload(values)
              resetForm()
            }}
            validateOnBlur
            validateOnChange
          >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
              <Form>
                <Paper square variant='outlined' className={classes.upload}>
                  <Grid container spacing={2} className={classes.form}>
                    <Grid item xs={12}>
                      <Typography variant='h6' className={classes.header}>Upload test results</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        accept='.xlsx'
                        className={classes.input}
                        id='file'
                        name='file'
                        onChange={(event) => {
                          setFieldValue('file', event.currentTarget.files[0])
                        }}
                        type='file'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body1'>Test Batch Date:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Field
                        component={TextField}
                        error={errors.testBatchDate && touched.testBatchDate}
                        fullWidth
                        helperText={errors.testBatchDate && touched.testBatchDate ? errors.testBatchDate : ''}
                        id='testBatchDate'
                        margin='dense'
                        name='testBatchDate'
                        onBlur={handleBlur}
                        onChange={(value) => {
                          handleChange(value)
                        }}
                        size='small'
                        type='date'
                        value={values.testBatchDate}
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>
                  {resultsError &&
                    <Typography
                      align='center'
                      className={classes.message}
                      color='error'
                      variant='body1'
                    >
                      Failed to upload results!
                    </Typography>}
                  {resultsUploaded &&
                    <Typography
                      align='center'
                      className={classes.message}
                      color='primary'
                      variant='body1'
                    >
                      Results uploaded.
                    </Typography>}
                </Paper>
                <div className={classes.buttons}>
                  <Button
                    className={classes.button}
                    color='primary'
                    onClick={() => { resetUploadForm() }}
                    type='reset'
                    variant='contained'
                  >
                    Clear
                  </Button>
                  <Button
                    className={classes.button}
                    color='primary'
                    disabled={values.testBatchDate === '' || isSubmitting}
                    type='submit'
                    variant='contained'
                  >
                    Upload
                  </Button>
                </div>
              </Form>
            )}
          </Formik>}
      </Container>
    )
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: 5
  },
  buttons: {
    float: 'right',
    marginBottom: 10,
    marginTop: 5
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  form: {
    alignItems: 'center'
  },
  header: {
    textTransform: 'uppercase'
  },
  logo: {
    marginTop: 10
  },
  logoImg: {
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '100%'
  },
  message: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  patient: {
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5
  },
  query: {
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5
  },
  upload: {
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5
  }
}))
