import api from '../api/api'

export default (dispatch) => async () => {
  console.log('Calling logout')
  // Modify state, so the button knows that we're requesting data.
  dispatch({ type: 'set_loading', payload: { key: 'loading', value: true } })

  // Clean storage.
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('user')
  localStorage.removeItem('canUploadResults')

  // Call API.
  api.post('/security/logout')

  // Remove the headers.
  api.defaults.headers.common.Authorization = null

  // Reload page
  window.location.reload(false)
}
